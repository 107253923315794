<template>
	<div>
		<a-spin :spinning="false">
		<div class="bg-w pd20" style="min-height: 800px;min-width: 1000px;">
			<div style="width: 800px;">
			<div class="ft20 ftw500 cl-main">企业微信接口配置</div>
			 
			
			
			<div v-if="datas!=null" class="mt20">
				<a-form-model
				   ref="ruleForm"
					:model="datas"
					:label-col="{ span: 4}" 
					:wrapper-col="{ span: 20}">	
					
					
					<a-alert class="mt20 mb20" message="要去企业微信后台建立企业应用代开发应用并审核上线,通用开发那边配置暂时同代开发应用,因为暂时没用到,那个TOKEN 和aeskey尽量和企业代开发应用一致即可" type="info" />
					
					<a-form-model-item label="CorpID">
						<a-input v-model="datas.corp_id" style="width: 300px;" placeholder="" />
					</a-form-model-item>
					<a-form-model-item label="ProviderSecret">
						<a-input v-model="datas.secret" style="width: 300px;" placeholder="" />
					</a-form-model-item>
					<a-form-model-item label="模板ID">
						<a-input v-model="datas.suite_id" style="width: 300px;" placeholder="" />
					</a-form-model-item>
					<a-form-model-item label="模板Secret">
						<a-input v-model="datas.suite_secret" style="width: 300px;" placeholder="" />
					</a-form-model-item>
					<a-form-model-item label="Token">
						<a-input v-model="datas.token" style="width: 300px;" placeholder="" />
					</a-form-model-item>
					<a-form-model-item label="EncodingAESKey">
						<a-input v-model="datas.aes_key" style="width: 300px;" placeholder="" />
					</a-form-model-item>
						
					<a-form-model-item label="回调URL" help="不用更改,要将这些配好后回调到企业代开发应用">
						<a-input v-model="datas.url" style="width: 300px;" placeholder="" />
					</a-form-model-item>
				
					<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
					      <a-button type="primary"  @click="saveData">
					        保存设置
					      </a-button>
					</a-form-model-item>
				</a-form-model>
			</div>
			</div>
		</div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false,
				datas:null,
				
			}
		},
		created(){
			this.getData();
		},
		methods:{
			addPrice(){
				let datas = JSON.parse(JSON.stringify(this.datas));
				datas.price.push({
					num:'',
					price:''
				});
				this.datas = datas;
			},
			delPrice(index){
				let price = JSON.parse(JSON.stringify(this.datas.price));
				price.splice(index,1);
				this.datas.price = price;
			},
			getData(){
				this.loading=true;
				this.$http.api('platform/admin/getWwopenSetting',{
					key:'wwopen'
				}).then(res=>{
					this.loading=false;
					this.datas = res.datas;
					if(this.datas.give_num == undefined){
						this.datas.give_num = 0;
						this.datas.price = new Array;
					}
				}).catch(res=>{
					this.loading=false;
				})
			},
			saveData(){
				this.loading=true;
				this.$http.api('platform/admin/saveSettingData',{
					key:'wwopen',
					datas:JSON.stringify(this.datas)
				}).then(res=>{
					this.loading=false;
					this.$message.success('保存成功');
				}).catch(res=>{
					this.loading=false;
				})
			}
		}
	}
</script>

<style>
	.sms-price-tit{
		width: 600px;
		height: 40px;
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		display: flex;
	}
	.sms-price-rows{
		width: 600px;
	}
</style>