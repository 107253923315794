<template>
	<div>
		<a-tabs v-model="type">
			  <a-tab-pane key="qiyeweixin" tab="企微配置">
				<qiyeweixin-setting></qiyeweixin-setting>
				
			  </a-tab-pane>
			  <a-tab-pane key="shop" tab="商家设置">
				<shop-setting></shop-setting>
			  </a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
	import qiyeweixinSetting from './components/setting/qiyeweixin.vue';
	import shopSetting from './components/setting/shop.vue';
	export default{
		components:{
			qiyeweixinSetting,
			shopSetting
		},
		data(){
			return {
				type:'qiyeweixin'
			}
		},
		created(){
			
		},
		methods:{
			callback(e){
				
			}
		}
	}
</script>

<style>
	
</style>