<template>
	<div>
		<div class="bg-w pd20" style="min-height: 800px;">
		
			<a-alert class="mt20 mb20" message="企业微信扫码后刷新本页面即可" type="info" />
			
			<a-button type="primary" icon="plus" @click="addAct()">添加自建授权</a-button>
			
			<div class="mt30">
				<div class="wxb-table-gray">
					<a-table rowKey="tpl_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" >
						<template slot="action" slot-scope="record">
							<div class="flex center">
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
									<a-menu slot="overlay" >
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="edit(record)">
												<i class="iconfont ft14 iconedit"></i>
												<span class="ml10">编辑模板</span>
											</a>
										</a-menu-item>
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="del(record)">
												<i class="iconfont ft14 icondelete"></i>
												<span class="ml10">删除模板</span>
											</a>
										</a-menu-item>
									</a-menu>
								</a-dropdown>
							</div>
						</template>
					</a-table>
				</div>
			</div>
			
			<div v-if="editSmsTplVisible">
				<edit-sms-tpl :visible="editSmsTplVisible"  :tpl_id="tpl_id" @cancel="cancelAct" @ok="okAct"></edit-sms-tpl>
			</div>
		</div>
	</div>
</template>

<script>
	import editSmsTpl from './shopedit.vue';
	export default{
		components:{
			editSmsTpl,
		},
		data(){
			return{
				loading:false,
				editSmsTplVisible:false,
				pagination: {
					current: 1,
					pageSize: 50, //每页中显示10条数据
					total: 0,
				},
				tpl_id:0,
				app:{
					name:'',
				},
				columns: [
					{title: '编号',dataIndex: 'tpl_id',align: 'center',ellipsis: true},
					{title: '企业名称',dataIndex: 'sms_id',align: 'center',ellipsis: true},
					{title: '企业ID',dataIndex: 'tpl',align: 'center',ellipsis: true},
					{title: '应用ID',dataIndex: 'key',align: 'center',ellipsis: true},
					{title: '企业LOGO',dataIndex: 'effect',align: 'center',ellipsis: true},
					{title: '企业号二维码',dataIndex: 'effect',align: 'center',ellipsis: true},
					{title: '开通时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		created() {
			this.getLists();
		},
		methods:{
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getWwopenStoreList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
					this.$router.go(-1);
				})
			},
			
			addAct(){
				this.tpl_id=0;
				this.editSmsTplVisible=true;
			},
			edit(record){
				this.tpl_id=record.tpl_id;
				this.editSmsTplVisible=true;
			},
			cancelAct(){
				this.editSmsTplVisible=false;
			},
			okAct(){
				this.editSmsTplVisible=false;
				this.getLists();
			},
			
			
			
			del(record){
				this.$confirm({
					title:'确认删除这个模板吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/admin/delSmsTpl',{
								tpl_id:record.tpl_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.dynamic-case-cover-img{
		width: 80px;
		height: 80px;
	}
</style>
